import LanguageSelect from '../LanguageSelect';
import { Navigation, Select } from '@groupbuilderoy/gb-components-library';
import { useContext, useEffect, useState } from 'react';
import ApartmentSelect from './ToolBar/ApartmentSelect/ApartmentSelect';
import ProjectSelect from './ToolBar/ProjectSelect/ProjectSelect';
import useStyles from './Header.styles';
import useT from 'hooks/useT';
import { useShowMessaging } from 'hooks/useShowMessaging';
import { MessagingPaths } from '@groupbuilderoy/messaging-client';
import { useHistory, useLocation } from 'react-router-dom';
import useGetCustomizationSettings from 'hooks/useGetCustomizationSettings';
import User from './ToolBar/User/User';
import { ProjectContext } from 'context/Project/ProjectContext';
import { AppContext } from 'context/App/AppContext';
import { useTrackers } from 'hooks/useTrackers';
import { Divider, Grid, lighten, useTheme } from '@material-ui/core';
import useCj2Loaded from 'hooks/useCj2Loaded';
import { PhaseContext } from 'context/Phase/PhaseContext';

/* eslint-disable */

enum Paths {
  Home = '/',
  Configurator = '/selections',
  ThreeD = '/3D'
}

/* eslint-enable */

function Header() {
  const theme = useTheme();
  const cj2Loaded = useCj2Loaded();
  const { pageView } = useTrackers();
  const history = useHistory();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const t = useT();
  const { projectState } = useContext(ProjectContext);
  const { messagingEnabled } = useShowMessaging();
  const customizationSettings = useGetCustomizationSettings();
  const { pathname } = useLocation();
  const { appState } = useContext(AppContext);
  const { phaseState, dispatch: dispatchPhases } = useContext(PhaseContext);
  const is3Dview = pathname === Paths.ThreeD;
  const isCJ2view = pathname === Paths.Configurator;
  const projectSettingsFetched = projectState.projectSettings;
  const materialSelectionsEnabled =
    projectSettingsFetched &&
    appState.tenantSettings?.customerJourney2_isActive &&
    projectState.projectSettings?.['cj2IntegratedIntoCj3'];
  const threeDEnabled =
    projectSettingsFetched &&
    appState.tenantSettings?.gb4dIsActive &&
    !projectState.projectSettings?.['cj2Disable3d'];
  // TODO: Implement phasesInTopMenu
  // const phasesInTopMenu = appState.tenantSettings?.phasesInTopMenu;
  const phasesInTopMenu = false;

  const detectTab = () => {
    switch (true) {
      case Object.values(MessagingPaths).some((path) => path === pathname):
        return 1;
      case isCJ2view:
        return 2;
      case is3Dview:
        return 3;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setSelectedTab(detectTab());
  }, [projectState.selectedProject?.id ?? null, pathname]);

  const navigate = (path: string) => {
    if (selectedTab === 2) {
      const iframe = document.getElementById('integratedAppIframe') as HTMLIFrameElement;
      if (iframe && cj2Loaded) {
        requestNavigationConfirmation(iframe)
          .then((allowNavigation) => {
            if (allowNavigation) {
              // User confirmed. Proceeding with navigation...
              history.push(path);
            } else {
              // User cancelled. Halting navigation...
            }
          })
          .catch(() => history.push(path));
      } else {
        history.push(path);
      }
    } else {
      history.push(path);
    }
  };

  function requestNavigationConfirmation(iframe: HTMLIFrameElement): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const messageHandler = (event: MessageEvent) => {
        if (event.data.type === 'NavigationDecision') {
          window.removeEventListener('message', messageHandler);
          clearTimeout(timeoutId); // Clear the timeout upon receiving a message
          resolve(event.data.allowNavigation);
        }
      };
      window.addEventListener('message', messageHandler);

      // Set a timeout to reject the promise if no response is received within a specified time
      const timeoutId = setTimeout(() => {
        window.removeEventListener('message', messageHandler); // Ensure to clean up the event listener
        reject(new Error('Navigation confirmation timeout'));
      }, 10000); // Timeout after 10000 milliseconds (10 seconds)

      iframe.contentWindow?.postMessage('exitingCJ2', '*');
    });
  }

  const handlePhaseChange = (id: number) => {
    navigate(Paths.Home);
    dispatchPhases({
      ...phaseState,
      selectedPhase: phaseState.phases.find((phase) => phase.id === id)
    });
  };
  const tabs: any =
    phasesInTopMenu && phaseState.phases.length > 0
      ? [
          {
            caption: t('home'),
            onClickTab: () => navigate(Paths.Home),
            customTab: (
              <div
                style={{
                  padding: '0 0.8rem',
                  height: '40px',
                  borderRadius: '8px',
                  background:
                    selectedTab === 0
                      ? lighten(
                          theme.palette.navBarPrimary?.main || 'white',
                          appState.startParameters.organisationId === 'srv' ? 0.4 : 0.2
                        )
                      : 'none',
                  display: 'flex'
                }}
              >
                <Select
                  data-testid='phase-selector'
                  caption={''}
                  // initialValue={projectState.projectSettings?.['activePhaseId'] || 0}
                  initialValue={phaseState.selectedPhase?.id || 0}
                  itemList={phaseState.phases}
                  identifierKey={'id'}
                  renderKey={'name'}
                  onChange={handlePhaseChange}
                  selectedItemColor={theme.palette.navBarSecondary?.main || 'white'}
                  toLink={'/project'}
                />
              </div>
            )
          }
        ]
      : [
          {
            caption: t('home'),
            onClickTab: () => navigate(Paths.Home)
          }
        ];
  if (messagingEnabled) {
    tabs.push({
      caption: t('messages'),
      onClickTab: () => navigate(MessagingPaths.ApartmentMessages)
    });
  }

  if (materialSelectionsEnabled) {
    tabs.push({
      caption: t('materialSelections'),
      onClickTab: () => {
        navigate(Paths.Configurator);
        pageView(Paths.Configurator);
      }
    });
  }

  if (threeDEnabled) {
    tabs.push({
      caption: t('3D'),
      onClickTab: () => {
        navigate(Paths.ThreeD);
        pageView(Paths.ThreeD);
      }
    });
  }

  const CustomDivider = () => {
    return (
      <Divider
        style={{
          background:
            appState.tenantSettings?.themeCustomizationSettings?.palette.navigationBarText ||
            'white',
          opacity: 0.5
        }}
      />
    );
  };

  // Special requirement from FEC
  const hideHomeHeader = projectState.loading || !!projectState.projectSettings?.hideHomeHeader;
  return (
    <div className={classes.root}>
      <Navigation
        logoUrl={customizationSettings.assets?.logo || undefined}
        headerImgUrl={customizationSettings.assets?.headerImg}
        selectedTab={selectedTab}
        hideHeaderImage={is3Dview || isCJ2view || hideHomeHeader}
        tabs={tabs.map((tab: any, index: any) => {
          return {
            id: index,
            ...tab
          };
        })}
        rightItems={
          <>
            <CustomDivider />
            <ProjectSelect />
            <ApartmentSelect />
            <CustomDivider />
            <Grid container style={{ justifyContent: 'space-between' }}>
              <LanguageSelect />
              <User />
            </Grid>
          </>
        }
      />
    </div>
  );
}

export default Header;
